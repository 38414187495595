.overlay[data-reach-dialog-overlay] {
  background: hsla(0, 0%, 0%, 0.8);
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.modal-content {
  background-color: var(--bg-modal-content-color);
  padding: 20px;

  position: relative;

  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;

  outline: 0;

  box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
}
