.progress-info {
  width: 100%;
  height: 10px;
  margin-top: 10px;
  margin-bottom: 0px;
  color: #0086c9;
}

.progress-alert {
  width: 100%;
  height: 10px;
  margin-top: 10px;
  margin-bottom: 0px;
  color: #f04438;
}
