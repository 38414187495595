.be-indicator {
  border: solid 1px var(--BE-only);
  border-radius: 15px;
  padding: 5px 10px;
  font-weight: 400;
  touch-action: all;
  pointer-events: all;
  white-space: nowrap;
}

.be-indicator .be-indicator-icon {
  @apply text-black th-highcontrast:text-white th-dark:text-blue-8;
}

.be-indicator:hover {
  @apply no-underline;
}

.be-indicator:hover .be-indicator-label {
  @apply underline;
}

.be-indicator-container {
  border: solid 1px var(--BE-only);
}
