.btn-none {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: 0;
}

.btn-none:focus {
  outline: none;
  color: inherit;
}

/* used for BE teaser. Dark theme specs defined by EE-5621 */
.btn-warninglight {
  @apply border-warning-5 bg-warning-2 text-black;
  @apply th-dark:border-blue-8 th-dark:bg-blue-8 th-dark:bg-opacity-10 th-dark:text-white;
  @apply th-highcontrast:bg-warning-5 th-highcontrast:bg-opacity-10 th-highcontrast:text-white;
}

.btn-none:active,
.btn-none:focus {
  outline: none;
  background-color: transparent;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.btn-box-shadow {
  box-shadow: none !important;
}

.btn-no-left-margin {
  margin-left: 0 !important;
}
