.menu-button {
  border: 0px;
  font-size: 17px;
  background: none;
  margin-right: 8px;
  display: flex;
  align-items: center;
}

.menu-icon {
  background: var(--user-menu-icon-color);
  position: relative;
}

.menu-list {
  background: var(--bg-dropdown-menu-color);
  border-radius: 8px;
  border: 1px solid var(--border-dropdown-menu-color) !important;
  min-width: 180px;
  padding: 5px !important;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  z-index: 9999;
  position: relative;
}

.menu-link {
  display: block;
  padding: 5px 20px;
  font-weight: 500;
  line-height: 1.42857143;
  white-space: nowrap;
  font-size: 14px;
  color: var(--text-dropdown-menu-color);
  text-decoration: none !important;
  border-radius: 5px;
}

.arrow-down {
  width: 18px;
  height: 18px;
  margin-left: 10px;
}

.menu-link:hover {
  background: var(--bg-dropdown-hover);
  color: var(--text-dropdown-menu-color);
}
