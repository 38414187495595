/*

** react-chat-widget css tree
** displayed against html tree
** top to bottom when chat is open

rcw-widget-container
  * rcw-conversation-container
    * rcw-header
      * rcw-close-button
      * rcw-title
    * rcw-messages-container
      * rcw-message
        * rcw-avatar
        * rcw-response
          * rcw-message-text
          * rcw-timestamp
      * rcw-message + rcw-message-client
        * rcw-client
          * rcw-message-text
          * rcw-timestamp
    * quick-buttons-container
      * quick-buttons
        * quick-list-button
          * quick-button
    * rcw-sender
      * rcw-picker-btn
        * rcw-picker-icon
      * rcw-new-message
        * rcw-input
      * rcw-send
        * rcw-send-icon

  * rcw-rcw-launcher

*/

.root {
  --rcw-absolute-max-height: calc(100vh - 40px);
  --rcw-absolute-max-width: calc(60vw - 40px);
  --rcw-absolute-max-width-xs: calc(90vw - 40px);
  --rcw-bg-elements-color: var(--bg-sidebar-nav-color);
  --rcw-bg-footer-color: var(--bg-body-color);
}
.root :global(.rcw-widget-container) {
  @apply flex flex-col justify-end;
  @apply m-0 mr-5 mb-5; /* override lib media query for small screen */
  @apply h-[var(--rcw-absolute-max-height)] max-h-[var(--rcw-absolute-max-height)];
  @apply w-[var(--rcw-absolute-max-width-xs)] max-w-[var(--rcw-absolute-max-width-xs)];
  @apply lg:w-[var(--rcw-absolute-max-width)] lg:max-w-[var(--rcw-absolute-max-width)];
}
.root :global(.rcw-close-widget-container) {
  @apply h-max w-max; /* avoid the container overlay the screen when the widget is closed */
}

/* 
 * CONVERSATION
 */
.root :global(.rcw-conversation-container) {
  @apply flex flex-col; /* make the conversation responsive in height */
  @apply flex-auto overflow-auto; /* allows the conversation to take the remaining space */
  @apply w-full max-w-full; /* override lib fixed values */
}

/* HEADER */
.root :global(.rcw-conversation-container .rcw-header) {
  @apply py-4; /* override lib uneven padding around header text */
  background-color: var(--rcw-bg-elements-color);
}
.root :global(.rcw-conversation-container .rcw-title) {
  @apply p-0 pb-4; /* override lib padding around header text*/
}

/* MESSAGES */
.root :global(.rcw-messages-container) {
  @apply h-auto max-h-full grow; /* override lib fixed value and let the messages take all the remaining available space */
  background-color: var(--bg-widget-color);
  @apply border-y border-x-0 border-solid;
  border-color: var(--border-widget);
}
.root :global(.rcw-message) {
  @apply whitespace-normal; /* override lib pre-wrap that generates a lot of space */
}
.root :global(.rcw-avatar) {
  @apply shrink-0; /* don't let the avatar be squished in width by the messages */
}
.root :global(.rcw-response .rcw-message-text) {
  @apply max-w-full; /* override fixed lib value */
  @apply border border-solid;
  @apply border-gray-5 bg-gray-2;
  @apply th-dark:border-gray-neutral-8 th-dark:bg-gray-iron-10;
  @apply th-highcontrast:border-white th-highcontrast:bg-black;
}
.root :global(.rcw-client .rcw-message-text) {
  @apply border border-solid;
  @apply border-blue-8 bg-blue-2;
  @apply th-dark:border-none th-dark:bg-blue-8;
  @apply th-highcontrast:border-white th-highcontrast:bg-black;
}
.root :global(.rcw-message-text li) {
  @apply list-inside; /* make the ::marker of the li live inside the element */
}
.root :global(.rcw-message-text li > p) {
  @apply inline; /* let ::marker be autogenerated but appear as part of the p content */
}

/* SENDER */
.root :global(.rcw-sender) {
  @apply flex flex-row items-center; /* make sender region responsive in width */
  @apply flex-shrink-0; /* don't let the sender box be squished in height by the messages */
  background-color: var(--rcw-bg-footer-color);
}
.root :global(.rcw-picker-btn) {
  @apply hidden; /* hide the emoji picker */
}
.root :global(.rcw-new-message) {
  @apply grow; /* allow the input to fill the entire widget width minus sender icon */
  background-color: var(--bg-inputbox);
  @apply border border-solid;
  border-color: var(--border-form-control-color);
  @apply th-highcontrast:border-white;
}
.root :global(.rcw-send) {
  @apply ml-2; /* add small left margin to send button */
  background-color: var(--rcw-bg-footer-color);
}

/* 
 * LAUNCHER
 */
.root :global(.rcw-launcher) {
  background-color: var(--rcw-bg-elements-color);
  @apply flex-shrink-0; /* don't let the launcher button be squished in height by the conversation panel */
  @apply m-0 mt-3; /* override lib media query for small screens  */
  @apply static; /* override lib media query for small screens */
}
.root :global(.rcw-launcher.rcw-hide-sm) {
  @apply block; /* override lib media query for small screens */
}
