.top-space-lg {
  margin-top: 30px;
}

.top-space-sm {
  margin-top: 5px;
}

.select {
  width: 160px;
  height: 25px;
}
