.license-info-panel {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  background: var(--bg-widget-color);
  border: 1px solid var(--border-widget);
  border-radius: 8px;
}

.license-alert-panel {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  background: var(--bg-widget-color);
  border: 1px solid #ee3528;
  border-radius: 8px;
  padding: 20px;
}

.license-home-info {
  padding: 10px 10px;
  color: #f04438;
  border: 1px solid #ee3528;
  background-color: #fef3f2;
  border-radius: 8px;
}

.license-info-content {
  padding: 20px 20px 10px 20px;
}

.license-title {
  font-weight: bold;
  font-size: 16px;
}

.divider {
  border: 0;
  border-top: 1px solid #eeeeee;
  margin: 0;
}

.extra {
  padding: 10px 20px;
}

.extra-less-two-months {
  margin-left: 5px;
  @apply text-warning-9 th-highcontrast:text-warning-1 th-dark:text-warning-7;
}

.alert-extra {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #fef3f2;
  border: 1px solid #ee3528;
  border-radius: 8px;
}

.alert-extra-text {
  margin-left: 5px;
  color: #f04438;
}

.license-block {
  display: flex;
  padding: 5px;
}

.icon {
  padding: 5px 10px 5px 0px;
}

.content {
  padding: 5px;
}

.button {
  margin-left: auto;
}
