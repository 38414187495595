.root {
  padding: 12px;
  margin-bottom: 20px;
  text-align: left;
  background-color: var(--ui-gray-9);
}

.dismissTitle {
  color: var(--white-color);
}

.dismissBtn {
  border: none;
  background: none;
  padding-left: 26px;
  color: var(--text-muted-color);
}

.actions {
  color: var(--blue-9);
  padding-left: 10px;
  padding-right: 5px;
  border: none;
  background: none;
}

.non-admin-action {
  color: var(--text-muted-color);
  padding-left: 10px;
  padding-right: 5px;
  border: none;
  background: none;
}
