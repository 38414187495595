.tool-bar {
  padding: 10px 20px;
  background: var(--bg-widget-table-color);
  display: flex;
  justify-content: space-between;
}

.tool-bar-segment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.line-number {
  max-width: 100px;
}
